<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Add Tag</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <b-form v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="tagData.name" @blur="tagData.name.$touch()"></b-form-input>
                <div v-if="$v.tagData.name.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.tagData.name.required">This is a required field</p>
                </div>
              </b-col>


            </b-row>
            <b-row v-if="true" class="mb-3">
              <b-col cols="12">
                <br />
                <h4
                  style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">
                  Reports</h4>
                <br />
              </b-col>
  <b-col cols="12">
              <b-form-group>
                <b-form-checkbox-group v-model="selectedLinks"  id="checkbox-group-1">
                  <b-form-checkbox v-for="(item, index) in reports" :key="index" :value="item" >{{item.name}}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>

  </b-col>
              <!-- <div class="form-group">
                <input :checked="links.checked" type="checkbox" v-for="(item, index) in links" :key="index"
                  :value="item.name" @blur="item.links.$touch()" />{{this.item.name}}

              </div> -->

            </b-row>
            <b-row v-if="true" class="mb-3">
              <b-col cols="12">
                <br />
                <h4
                  style="text-align: center; background: #858484; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0;">
                  Apps</h4>
                <br />
              </b-col>
                <b-col cols="12">
              <b-form-group>
                <b-form-checkbox-group v-model="selectedLinks" id="checkbox-group-1">
                  <b-form-checkbox v-for="(item, index) in apps" :key="index" :value="item">{{item.name}}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="true" class="mb-3">
              <b-col cols="12">
                <br />
                <h4
                  style="text-align: center; background: #acacac; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0;">
                  Services</h4>

                <br />
              </b-col>
               <b-col cols="12">
              <b-form-group>
                <b-form-checkbox-group v-model="selectedLinks" id="checkbox-group-1">
                  <b-form-checkbox v-for="(item, index) in services" :key="index" :value="item">{{item.name}}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
               </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch()" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submit()" class="ml-2">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
  .primary {
    background-color: #ffcb14;
    color: #222222;
  }

  .primary:hover {
    color: #fff;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
  }
</style>
<script>
  import {
    mapActions,
    mapMutations
  } from "vuex";
  import {
    required
  } from "vuelidate/lib/validators";
  import api from "../../../api";

  export default {
    name: "createTag",

    data: () => ({
      state: 'show',
      tagData: {
        name: null,
        links: []      
      },
      links: [],
      reports:[],
      apps: [],
      services: [],
      selectedLinks: []
    }),
    beforeMount() {

      this.setBreadcrumb([{
          text: 'Tag'
        },
        {
          text: 'Add New Tag'
        },
      ]);

      const s = (data) => {
        this.links = data;

        for (var i in data) {
          var service = data[i];
          if (service.grouping.name === 'Reports') {
            this.reports.push(service);
          }

          if (service.grouping.name == 'Application') {
            this.apps.push(service);
          }

          if (service.grouping.name == 'Services') {
            this.services.push(service);
          }
        }
      };

      const e = (msg) => {
        console.log(msg);
      };
      api.getAllLinks(s, e);

    },
    methods: {
      ...mapActions(["createTag"]),
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      ...mapActions('notificationService', ['addWarningMessage']),

      submit() {
        const s = (data) => {
          this.$router.push({path: '/admin/ManageTags/SearchTag'})
          console.log(data);
        };

        this.selectedLinks.forEach(item => this.tagData.links.push(item.id));

        const e = (msg) => {
          console.log(msg);
        };
        
        api.CreateTag(this.tagData, s, e)
      },

      goBackToSearch() {
        this.$router.push({
          path: '/admin/ManageTags/SearchTag'
        })
      },
    },
    computed: {},
    validations: {
      tagData: {
        name: {
          required
        },
      },
    },
  }
</script>

<style scoped>

</style>